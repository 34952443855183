<template>
  <div class="components__rec-select">
    <div class="label-container">
      <label class="very-small" for="wbb_select">{{ description.label }}</label>
    </div>
    <div class="select-container">
      <select
        id="wbb_select"
        v-bind:name="description.name"
        v-model="description.ivalue"
      >
        <option v-for="option in description.options"
          v-bind:value="option.value"
          v-bind:key="option.value"
        >{{ option.text }}</option>
      </select>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~stylesheets/frontend/variables/colors";

.components__rec-select {
  .label-container {
    margin-bottom: 3px;
  }

  label {
    width: 100%;

    color: $color-default;
    font-size: 14px;
  }
}
</style>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
};
</script>
